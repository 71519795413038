import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Button from './Button'
import Dropdown from './Dropdown'
import ChevronLeft from '../assets/chevron-left.svg'

const Nav = styled.nav`
  display: flex;
  z-index: 5000;
  flex-wrap: nowrap;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  ${'' /* margin-left: 100px; */}
  @media (max-width: 1280px) {
    margin-left: 0vw;
  }
  @media (max-width: 1142px) {
    margin-left: 1vw;
    justify-content: space-evenly;
  }
  @media (max-width: 1065px) {
    margin-left: 0;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`

const NavWrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  ${'' /* padding-inline-end: 0;
  padding-inline-start: 0; */}
  margin-inline: auto;
  margin-bottom: 0px;
  visibility: ${(props) => (props.isLandingPage ? 'hidden' : 'visible')};
  @media (max-width: 1280px) {
    padding-left: 12px;
  }
  @media (max-width: 1024px) {
    flex-flow: column nowrap;
    padding-left: 2rem;
  }
  @media (max-width: 1124px) {
    padding-left: 0px;
  }
`
const NavItem = styled.li`
  padding-right: 45px;
  color: #3b4b66;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.2s ease-in;
  &:first-child {
    padding-right: 0;
    div {
      @media (max-width: 1124px) {
        padding-right: 24px;
      }
      @media (max-width: 1065px) {
        padding-right: 12px;
      }
    }
  }
  @media (max-width: 1124px) {
    padding-right: 30px;
  }
  @media (max-width: 1065px) {
    padding-right: 12px;
  }
`

const NavLink = styled(Link)`
  color: ${(props) => (props.white ? '#fff' : '#191919')};
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: color 0.2s ease-in;
`

const NavLinkExternal = styled.a`
  color: ${(props) => (props.white ? '#fff' : '#191919')};
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: color 0.2s ease-in;
`

const ButtonsWrapper = styled.div`
  display: inline-block;
  a {
    padding: 8px 34px;
    @media (max-width: 1224px) {
      padding: 8px 24px;
    }
  }
  a:first-child {
    margin-right: 35px;
    @media (min-width: 1024px) {
      margin-right: 14px;
    }
    @media (min-width: 1240px) {
      margin-right: 35px;
    }
    @media (max-width: 1224px) {
      padding: 8px 24px;
    }
    ${({ white }) =>
      white
        ? `
    border-color: #fff !important;
    background-color: #fff !important;
    :hover {
      background-color: #5a55ab !important;
    }
    `
        : ''}
  }

  ${({ white }) =>
    white
      ? `
    a:last-child {
      border-color: #fff !important;
      color: #fff;
      :hover {
        background-color: #fff !important;
      }
    }
  `
      : ''}
`

const ReturnLink = styled.div`
  font-size: 14px;
  margin-right: 15px;
  display: flex;

  & > a {
    font-size: 13px;
    color: #8c88c4;
    line-height: 22px;
    font-style: normal;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 0;
    @media (max-width: 1224px) {
      padding: 8px 0px !important;
    }
  }
`
const DropdownTrigger = styled.div`
  position: relative;

  cursor: pointer;

  span {
    display: inline-block;
    color: ${(props) => (props.isCurrent ? '#5a55ab' : '#191919')};
    font-size: 16px;
    font-weight: ${(props) => (props.isCurrent ? '600' : '400')};
    white-space: nowrap;
    width: 72px;
    padding: 0px 2px;
    transition: transform 0.2s ease !important;
    transform-origin: center;
    transform: ${(props) => (props.isCurrent ? 'scale(1.05)' : 'scale(1)')};
  }

  &:hover span {
    transform: ${(props) => (!props.isCurrent ? 'scale(1.05)' : 'scale(1.05)')};
    color: ${(props) => (!props.isCurrent ? '#5a55ab' : '#5a55ab')};
    font-weight: ${(props) => (!props.isCurrent ? '600' : '600')};
    font-size: 16px;
  }
`
const pages = {
  lp: {
    pagename: 'Landing Page',
    link: '/',
  },
  hd: {
    pagename: 'Homepage',
    link: '/learning',
  },
  csce: {
    pagename: 'Continuous Education',
    link: '/use-cases/continuous-education',
  },
  csob: {
    pagename: 'Onboarding',
    link: '/use-cases/onboarding',
  },
  csit: {
    pagename: 'Internal Training',
    link: '/use-cases/internal-training',
  },
  csc: {
    pagename: 'Certifications',
    link: '/use-cases/certifications',
  },
  cscp: {
    pagename: 'Career Paths',
    link: '/use-cases/career-paths',
  },
  cs: {
    pagename: 'Innential Content Search',
    link: '/content-search-ai',
  },
  default: {
    pagename: 'Landing Page',
    link: '/',
  },
}

const Navigation = ({
  white,
  isSignUpOrAppointment,
  isUseCases,
  isBlog,
  isLearningPaths,
  isLibrary,
  isLandingPage,
}) => {
  const safeWindow = typeof window === 'undefined' ? {} : window

  const ref = new URLSearchParams(safeWindow?.location?.search).get('ref')

  const { pagename, link } = pages[ref] || pages.default

  return (
    <Nav>
      <NavWrapper isLandingPage={isLandingPage}>
        <NavItem>
          <Dropdown white={false} isSolutions isCurrent={isUseCases} />
        </NavItem>
        <NavItem>
          {/* <DropdownTrigger isCurrent={isLearningPaths}>
            <NavLink white={false} to="/learning-paths">
              <span style={{ width: '120px' }}>Resources</span>
            </NavLink>
          </DropdownTrigger> */}
          <Dropdown white={false} isResources isCurrent={isLearningPaths} />
        </NavItem>
        <NavItem>
          <DropdownTrigger>
            <NavLinkExternal
              white={false}
              href="https://www.service-assistant.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ width: '173px', paddingLeft: '13px' }}>
                Service Assistant AI
              </span>
            </NavLinkExternal>
          </DropdownTrigger>
        </NavItem>
        <NavItem style={{ paddingRight: '10px' }}>
          <DropdownTrigger isCurrent={isBlog}>
            <NavLinkExternal white={false} href="https://library.innential.com">
              <span style={{ width: '130px' }}>Content Library</span>
            </NavLinkExternal>
          </DropdownTrigger>
        </NavItem>
        {/* <NavItem>
          <NavLinkExternal
            white={white}
            href="https://talentdevelopmentcompass.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Compass
          </NavLinkExternal>
        </NavItem> */}
      </NavWrapper>
      <ButtonsWrapper white={false}>
        {!isSignUpOrAppointment ? (
          <>
            <Button
              cta={false}
              primary={false}
              secondary
              link="https://app.innential.com/login"
              target="_blank"
              title="Sign in"
              smallPadding
            />
            <Button
              cta={false}
              primary
              secondary={false}
              link={isLandingPage ? '/sign-up?ref=lp' : '/sign-up?ref=hd'}
              title="Get started"
              smallPadding
            />
          </>
        ) : (
          <div style={{ display: 'flex' }}>
            <ReturnLink>
              <Link to={link}>
                <img src={ChevronLeft} alt="chevron" /> Return to {pagename}
              </Link>
            </ReturnLink>
            <Button
              cta={false}
              primary={false}
              secondary
              link="https://app.innential.com/login"
              target="_blank"
              title="Sign in"
              smallPadding
            />
          </div>
        )}
      </ButtonsWrapper>
    </Nav>
  )
}

export default Navigation
