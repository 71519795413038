/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

const Btn = styled.a`
  display: inline-block;
  width: auto;
  height: auto;
  ${'' /* padding: 10px 50px; */}
  font-size: ${(props) => (props.cta ? '16px' : '14px')};
  font-weight: 700;
  letter-spacing: 0.25px;
  border-radius: 26px;
  border: 1px solid;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

  color: ${(props) => (props.primary ? '#fff' : '#b8dee2')};
  background-color: ${(props) => (props.primary ? '#5a55ab' : 'transparent')};
  border-color: ${(props) => (props.primary ? '#5a55ab !important' : '#fff')};

  color: ${(props) => (props.secondary ? '#5a55ab' : '#fff')};
  background-color: ${(props) => (props.secondary ? 'transparent' : '#5a55ab')};
  border-color: ${(props) => (props.secondary ? '#5a55ab' : '#fff')};

  /* color: ${(props) => (props.cta ? '#fff' : '#5a55ab')};
  background-color: ${(props) => (props.cta ? 'transparent' : '#5a55ab')};
  border-color: ${(props) => (props.cta ? '#fff' : '#5a55ab')}; */

  padding: ${(props) => (props.smallPadding ? '10px 24px' : '10px 50px')};
  &:hover,
  active,
  focus {
    color: ${(props) => (props.secondary ? '#b8dee2' : '#fff')};
    background-color: ${(props) => (props.secondary ? '#fff' : '#5a55ab')};
    border-color: ${(props) =>
      props.secondary ? '#5a55ab !important' : '#fff'};

    color: ${(props) => (props.primary ? '#5a55ab !important' : '#fff')};
    background-color: ${(props) =>
      props.primary ? 'transparent' : '#5a55ab '};
    border-color: ${(props) => (props.primary ? '5a55ab !important' : '#fff')};
  }
`

const Button = ({
  link,
  target,
  title,
  primary,
  secondary,
  cta,
  style,
  onClick,
  children,
  smallPadding,
}) => (
  <Btn
    primary={primary}
    secondary={secondary}
    cta={cta}
    href={link}
    target={target}
    rel="noreferrer"
    style={{ ...style }}
    onClick={onClick}
    smallPadding={smallPadding}
  >
    {title}
    {children}
  </Btn>
)

export default Button
