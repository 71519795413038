/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Button from './Button'
import DropdownMobile from './DropdownMobile'
import logo from '../assets/innential.svg'

const Nav = styled.nav`
  display: none;
  z-index: 5000;
  flex-wrap: nowrap;
  align-items: baseline;
  z-index: 5000;
  background-color: #fff;
  width: 100%;
  justify-content: space-between;
  margin-left: 100px;
  @media (max-width: 1024px) {
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 348px;
    padding: 8px 40px 39px;
    transition: ${(props) =>
        props.isClicked ? 'none' : 'transform 0.3s ease-in-out'}transform
      0.3s ease-in-out;
    overflow-y: scroll;
  }
`
const NavWrapper = styled.ul`
  background-color: #fff;
  list-style: none;
  display: flex;
  padding-inline-end: 0;
  padding-inline-start: 0;
  flex-flow: column nowrap;
  width: 100%;
  padding-top: 50px;
`
const NavItem = styled.li`
  width: 100%;
  color: #191919;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.2s ease-in;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 18px 2px;
  display: flex;
  flex-direction: column;
  gap: 27px;
`

const NavLink = styled(Link)`
  color: #191919;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.2s ease-in;
  &:hover {
    color: #5a55ab;
  }
`

const GetStartedLink = styled(Link)`
  color: #5a55ab;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.2s ease-in;
`
const NavLinkExternal = styled.a`
  color: #191919;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.2s ease-in;
  &:hover {
    color: #5a55ab;
  }
`
const LinksWrapper = styled.div`
  display: ${(props) => (props.isLandingPage ? 'none' : 'block')};
`
const MobileNavigation = ({ open, closeMobileNavigation, isLandingPage }) => {
  const [isClicked, setIsClicked] = React.useState(false)
  const handleClose = () => {
    setIsClicked(true)
    setTimeout(() => {
      setIsClicked(false)
    }, 300)
    closeMobileNavigation()
  }
  return (
    <Nav open={open} isClicked={isClicked}>
      <NavWrapper>
        <LinksWrapper isLandingPage={isLandingPage}>
          <NavItem style={{ paddingBottom: '0px', border: 'none' }}>
            <DropdownMobile closeMobileNavigation={closeMobileNavigation} />
          </NavItem>

          <NavItem style={{ paddingBottom: '0px', border: 'none' }}>
            {/* <NavLink to="/learning-paths">Learning Paths</NavLink> */}
            <DropdownMobile
              closeMobileNavigation={closeMobileNavigation}
              isResources
            />
          </NavItem>
          <NavItem onClick={handleClose}>
            <NavLinkExternal
              white={false}
              href="https://www.service-assistant.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              Service Assistant AI
            </NavLinkExternal>
          </NavItem>
          <NavItem onClick={handleClose}>
            <NavLinkExternal white={false} href="https://library.innential.com">
              Content Library
            </NavLinkExternal>
          </NavItem>
        </LinksWrapper>
        {/* <NavItem onClick={closeMobileNavigation}>
        <GetStartedLink to="/sign-up?ref=hd">Request a demo</GetStartedLink>
      </NavItem> */}
        <NavItem onClick={handleClose} style={{ borderBottom: 'none' }}>
          <Button
            cta={false}
            primary
            secondary={false}
            link="/sign-up?ref=hd"
            title="Get started"
            smallPadding
            style={{ textAlign: 'center', fontSize: '16px' }}
          />
          <Button
            cta={false}
            primary={false}
            secondary
            link="https://app.innential.com/login"
            target="_blank"
            title="Sign in"
            smallPadding
            style={{ textAlign: 'center', fontSize: '16px' }}
          />
        </NavItem>
        {/* <NavItem>
        <NavLinkExternal
          href="https://talentdevelopmentcompass.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Compass
        </NavLinkExternal>
      </NavItem> */}
      </NavWrapper>
      {/* <NavWrapper>
      <NavItem>
        <Dropdown />
      </NavItem>
      <NavItem><NavLink to={'/learning-paths'}>Learning Paths</NavLink></NavItem>    
      <NavItem><NavLink to={'/blog'}>Blog</NavLink></NavItem>  
    </NavWrapper>
    <ButtonsWrapper>
      <Button  cta={false} primary={false} secondary={true}link={"/"} target={"_blank"} title={"Sign in"} />
      <Button cta={false} primary={true} secondary={false} link={"/"} target={"_blank"} title={"Get started"} />
    </ButtonsWrapper> */}
    </Nav>
  )
}

export default MobileNavigation
