import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import device from '../utils/mq'
import liIcon from '../assets/icons/linkedin.png'

const FooterWrapper = styled.footer`
  background-color: ${(props) => (props.white ? '#fff' : '#f7f7ff')};
  padding: 47px 0 25px;
  position: relative;
  margin-top: auto;
`
const FooterNarrow = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto;
  align-items: center;
`

const FooterNav = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px 1.0875rem;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  @media ${device.mobileL} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  @media ${device.laptop} {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-right: 60px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    @media ${device.laptop} {
      align-items: flex-start;
      flex-shrink: auto;
      width: auto;
    }
  }
`

const FooterContact = styled(FooterColumn)`
  max-width: 339px;
  @media (min-width: 1024px) {
    max-width: 100%;
  }
`
const FooterRow = styled.div`
  display: flex;
  align-items: baseline;
`

const FooterHeader = styled.h6`
  margin: 15px 0;
  color: #5a55ab;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
  @media ${device.laptop} {
    margin: 25px 0;
  }
`
const FooterLabel = styled.span`
  margin: 0 5px 0 0;
  font-size: 14px;
  color: ${(props) => (props.white ? '#8494B2' : '#939393')};
  font-weight: 400;
`

const FooterItem = styled.span`
  color: ${(props) => (props.white ? '#152540' : '#1d1d1b')};
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 2px 0;
  margin-bottom: 20px;
  a {
    padding: 5px 0;
    color: ${(props) => (props.white ? '#152540' : '#1d1d1b')};
    display: block;
    font-size: 14px;
    font-weight: 400;

    &:hover,
    &:active,
    &:focus,
    a:visited {
      color: #5a55ab;
    }
  }
`

const FooterSocial = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  a {
    line-height: 16px;
  }

  img {
    height: 16px;
    margin: 0 20px;
  }

  span {
    padding: 0 20px;
    color: #5a55ab;
    font-weight: 400;
    line-height: 16px;
    border-right: 1px solid #1d1d1b;
  }
`
const FooterLeft = styled.div`
  display: flex;
  justify-content: center;
  div {
    margin: 0 12px;
  }
  @media ${device.tablet} {
    div:not(:first-child) {
      margin-left: 35px;
    }
  }
  @media ${device.laptop} {
    div {
      margin-left: 55px;
    }
  }
  @media (max-width: 646px) {
    width: 339px;
    justify-content: space-between;
    div {
      margin-left: 0;
    }
  }
  @media (max-width: 400px) {
    width: inherit;
  }
`

export default function Footer({ white, isLandingPage }) {
  return (
    <FooterWrapper white={white}>
      <FooterNarrow>
        <FooterNav>
          <FooterContact>
            <FooterHeader>Contact us</FooterHeader>
            <FooterRow>
              <FooterLabel white={white}>email:</FooterLabel>
              <FooterItem white={white}>
                <a href="mailto:contact@innential.com">contact@innential.com</a>
              </FooterItem>
            </FooterRow>
            <FooterRow>
              <FooterLabel white={white}>phone:</FooterLabel>
              <FooterItem white={white}>
                <a href="tel:+4915750455455">+49 1575 0455455</a>
              </FooterItem>
            </FooterRow>
            <FooterRow>
              <FooterLabel white={white}>address:</FooterLabel>
              <FooterItem white={white}>
                Innential UG Bergheimer Str. 104 69115 Heidelberg
              </FooterItem>
            </FooterRow>
          </FooterContact>
          <FooterLeft>
            {isLandingPage ? (
              <FooterColumn>
                <FooterHeader>Products</FooterHeader>
                <FooterItem>
                  <Link to="/learning">Learning</Link>
                </FooterItem>
                <FooterItem>
                  <Link
                    to="https://www.service-assistant.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Service Assistant AI
                  </Link>
                </FooterItem>
              </FooterColumn>
            ) : (
              <FooterColumn>
                <FooterHeader>Use Cases</FooterHeader>
                <FooterItem>
                  <Link to="/use-cases/onboarding">Onboarding</Link>
                </FooterItem>
                <FooterItem>
                  <Link to="/use-cases/continuous-education">
                    Continuous Education
                  </Link>
                </FooterItem>
                <FooterItem>
                  <Link to="/use-cases/internal-training">
                    Internal Training
                  </Link>
                </FooterItem>
                <FooterItem>
                  <Link to="/use-cases/career-paths">Career Paths</Link>
                </FooterItem>
                {/* <FooterItem>
                <Link to="/solutions/technology-teams">Technology Teams</Link>
              </FooterItem>
              <FooterItem>
                <Link to="/solutions/remote-teams">Remote Teams</Link>
              </FooterItem> */}
              </FooterColumn>
            )}
            <FooterColumn>
              <FooterHeader>Company</FooterHeader>
              <FooterItem>
                <Link to="/about">About us</Link>
              </FooterItem>
              <FooterItem>
                <Link to="/blog">Blog</Link>
              </FooterItem>
            </FooterColumn>
            <FooterColumn>
              <FooterHeader>Legal</FooterHeader>
              <FooterItem white={white}>
                <Link to="/imprint">Imprint</Link>
              </FooterItem>
              <FooterItem white={white}>
                <Link to="/data-privacy">Data privacy</Link>
              </FooterItem>
            </FooterColumn>
          </FooterLeft>
        </FooterNav>
        <FooterSocial>
          <span>Innential {new Date().getFullYear()}</span>
          <a
            href="https://www.linkedin.com/company/15176503/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={liIcon} alt="LinkedIn icon" />
          </a>
        </FooterSocial>
      </FooterNarrow>
    </FooterWrapper>
  )
}
