import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import OnboardingIcon from '../assets/Group-person.svg'
import CareerIcon from '../assets/career-path.svg'
import ContinuosIcon from '../assets/Trending-up.svg'
import TrainingIcon from '../assets/Folder-Marked.svg'
import CertBGIcon from '../assets/cert-bg.svg'
import AwardIcon from '../assets/award.svg'
import LPIcon from '../assets/Icon-lp.svg'
import GuideIcon from '../assets/file-text.svg'
import LibraryIcon from '../assets/library.svg'
import BlogIcon from '../assets/blog.svg'

const NavWrapper = styled.div`
  padding-right: 45px;
  cursor: pointer;
  max-width: ${(props) => (props.isResources ? '100px' : 'auto')};
`

const NavItem = styled.div`
  padding-right: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
`
const NavItemImgContainer = styled.div`
  position: relative;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`

const NavLink = styled(Link)`
  color: #191919;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: color 0.2s ease-in;
  white-space: nowrap;
  display: flex;
  align-items: center;
  &:hover {
    color: #5a55ab;
  }
  span {
    color: #fff !important;
    background-color: #2fbbb0;
    border-radius: 24px;
    padding: 0px 6px !important;
    font-size: 10px !important;
    font-weight: 700;
    line-height: 16px;
    width: 38px !important;
    text-align: center;
    margin-left: 4px;
  }
`
const DropdownTrigger = styled.button`
  position: relative;
  border: none;
  background: none;
  display: flex;

  span {
    display: inline-block;
    width: 72px;
    padding: 0 2px;
    font-size: 16px;
    transition: transform 0.1s ease;
    transform-origin: center;
    transform: ${(props) =>
      props.show || props.isCurrent ? 'scale(1.05)' : 'scale(1)'};
    color: ${(props) =>
      props.show || props.isCurrent ? '#5A55AB' : '#191919'};
    font-weight: ${(props) => (props.show || props.isCurrent ? '600' : '400')};
  }

  &:hover span {
    transform: ${(props) => (!props.isCurrent ? 'scale(1.05)' : 'scale(1.05)')};
    color: ${(props) => (!props.isCurrent ? '#5a55ab' : '#5a55ab')};
    font-weight: ${(props) => (!props.isCurrent ? '600' : '600')};
    font-size: 16px;
  }
`
const DropdownMenuContainer = styled.div`
  position: absolute;
  width: auto;
  left: ${(props) => (props.isResources ? '-128px' : '0')};
  top: 58px;
  padding-top: 36px;
  padding-left: 8px;
  z-index: 100;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  height: ${(props) => (props.show ? '168px' : '0')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: ${(props) =>
    props.isClicked
      ? 'none'
      : 'height 0.1s ease-in-out, opacity 0.1s ease-in-out, visibility 0.1s ease-in-out'};
`
const DropdownMenuContainerBackground = styled.div`
  position: fixed;
  width: auto;
  left: 0;
  top: 62px;
  background: #fff;
  z-index: 99;
  width: 100vw;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  height: ${(props) => (props.show ? '263px' : '0')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: ${(props) =>
    props.isClicked
      ? 'none'
      : 'height 0.1s ease-in-out, opacity 0.1s ease-in-out, visibility 0.1s ease-in-out'};
  cursor: default;
`
const DropdownMenu = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px 32px;
  padding-top: 20px;
`
const DropdownMenuSmall = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 32px;
  padding-top: 20px;
`
const DropdownTitle = styled.div`
  color: #191919;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
`

function Dropdown({ isCurrent, isSolutions, isResources }) {
  const [isShown, setIsShown] = useState(false)
  const [timer, setTimer] = useState(null)
  const [isClicked, setIsClicked] = useState(false)

  const handleMouseLeave = () => {
    const timerId = setTimeout(() => setIsShown(false), 100)
    setTimer(timerId)
  }

  const handleMouseEnter = () => {
    setIsClicked(false)
    setIsShown(true)
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
  }
  const handleMouseEnterContainer = () => {
    const timerId = setTimeout(() => setIsShown(true), 100)
    setTimer(timerId)
  }

  const handleMouseClickContainer = () => {
    setIsClicked(true)
    const timerId = setTimeout(() => {
      setIsShown(false)
    }, 0)
    setTimer(timerId)
  }

  return (
    <NavWrapper isResources={isResources}>
      {isSolutions ? (
        <DropdownTrigger
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          show={isShown}
          onClick={() => setIsShown(true)}
          isCurrent={isCurrent}
        >
          <span>Solutions</span>
          <DropdownMenuContainer
            onMouseEnter={handleMouseEnterContainer}
            onMouseLeave={handleMouseEnterContainer}
            show={isShown}
            isClicked={isClicked}
          >
            {/* <DropdownTitle>Solutions</DropdownTitle> */}
            <DropdownMenu>
              <NavItem onClick={handleMouseClickContainer}>
                <NavItemImgContainer>
                  <img src={OnboardingIcon} alt="onboarding" />
                </NavItemImgContainer>
                <NavLink to="/use-cases/onboarding">Onboarding</NavLink>
              </NavItem>
              <NavItem onClick={handleMouseClickContainer}>
                <NavItemImgContainer>
                  <img src={CareerIcon} alt="career paths" />{' '}
                </NavItemImgContainer>
                <NavLink to="/use-cases/career-paths">Career Paths</NavLink>
              </NavItem>

              <NavItem onClick={handleMouseClickContainer}>
                <NavItemImgContainer>
                  <img src={ContinuosIcon} alt="continuous education" />
                </NavItemImgContainer>
                <NavLink to="/use-cases/continuous-education">
                  Continuous Education
                </NavLink>
              </NavItem>
              <NavItem onClick={handleMouseClickContainer}>
                <NavItemImgContainer>
                  <img src={TrainingIcon} alt="internal training" />{' '}
                </NavItemImgContainer>
                <NavLink to="/use-cases/internal-training">
                  Internal Training
                </NavLink>
              </NavItem>
              <NavItem onClick={handleMouseClickContainer}>
                <NavItemImgContainer>
                  <img src={CertBGIcon} alt="Certifications" />
                  <img
                    src={AwardIcon}
                    alt="Certifications"
                    style={{
                      position: 'absolute',
                      width: '11px',
                      height: '11px',
                      left: '5px',
                      top: '5px',
                    }}
                  />
                </NavItemImgContainer>
                <NavLink to="/use-cases/certifications">Certifications</NavLink>
              </NavItem>
            </DropdownMenu>
          </DropdownMenuContainer>
          <DropdownMenuContainerBackground
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            show={isShown}
            isClicked={isClicked}
          />
        </DropdownTrigger>
      ) : (
        <DropdownTrigger
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          show={isShown}
          onClick={() => setIsShown(true)}
          isCurrent={isCurrent}
        >
          <span style={{ width: '100px' }}>Resources</span>
          <DropdownMenuContainer
            onMouseEnter={handleMouseEnterContainer}
            onMouseLeave={handleMouseEnterContainer}
            show={isShown}
            isClicked={isClicked}
            isResources={isResources}
          >
            {/* <DropdownTitle>Solutions</DropdownTitle> */}
            <DropdownMenuSmall>
              <NavItem onClick={handleMouseClickContainer}>
                <NavItemImgContainer>
                  <img src={LPIcon} alt="learning paths" />
                </NavItemImgContainer>
                <NavLink to="/learning-paths">Learning Paths</NavLink>
              </NavItem>
              <NavItem onClick={handleMouseClickContainer}>
                <NavItemImgContainer>
                  <img src={GuideIcon} alt="guide" />
                </NavItemImgContainer>
                <NavLink to="/download/people-development-blueprint">
                  People Development Blueprint <span>NEW</span>
                </NavLink>
              </NavItem>
              <NavItem onClick={handleMouseClickContainer}>
                <NavItemImgContainer>
                  <img src={LibraryIcon} alt="library" />
                </NavItemImgContainer>
                <NavLink to="/virtual-library/read">L&D Library</NavLink>
              </NavItem>
              <NavItem onClick={handleMouseClickContainer}>
                <NavItemImgContainer>
                  <img src={BlogIcon} alt="blog" />
                </NavItemImgContainer>
                <NavLink to="/blog">Blog</NavLink>
              </NavItem>
            </DropdownMenuSmall>
          </DropdownMenuContainer>
          <DropdownMenuContainerBackground
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            show={isShown}
            isClicked={isClicked}
          />
        </DropdownTrigger>
      )}
    </NavWrapper>
  )
}

export default Dropdown
