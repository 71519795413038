/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react'

export const BookIcon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3C1 2.44772 1.44772 2 2 2H8C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21C11 20.4696 10.7893 19.9609 10.4142 19.5858C10.0391 19.2107 9.53043 19 9 19H2C1.44772 19 1 18.5523 1 18V3ZM11 17.5359V7C11 6.20435 10.6839 5.44129 10.1213 4.87868C9.55871 4.31607 8.79565 4 8 4H3V17H9C9.70823 17 10.3971 17.1878 11 17.5359Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4645 3.46447C13.4021 2.52678 14.6739 2 16 2H22C22.5523 2 23 2.44772 23 3V18C23 18.5523 22.5523 19 22 19H15C14.4696 19 13.9609 19.2107 13.5858 19.5858C13.2107 19.9609 13 20.4696 13 21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V7C11 5.67392 11.5268 4.40215 12.4645 3.46447ZM13 17.5359C13.6029 17.1878 14.2918 17 15 17H21V4H16C15.2044 4 14.4413 4.31607 13.8787 4.87868C13.3161 5.44129 13 6.20435 13 7V17.5359Z"
      fill={fill}
    />
  </svg>
)

export const SpeakerIcon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4332 4.0987C11.7797 4.26522 12 4.6156 12 5V19C12 19.3844 11.7797 19.7348 11.4332 19.9013C11.0867 20.0678 10.6755 20.021 10.3753 19.7809L5.64922 16H2C1.44772 16 1 15.5523 1 15V9C1 8.44772 1.44772 8 2 8H5.64922L10.3753 4.21913C10.6755 3.979 11.0867 3.93218 11.4332 4.0987ZM10 7.08063L6.62469 9.78087C6.44738 9.92272 6.22707 10 6 10H3V14H6C6.22707 14 6.44738 14.0773 6.62469 14.2191L10 16.9194V7.08063Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.363 4.22279C18.7536 3.83232 19.3868 3.83242 19.7773 4.223C21.8394 6.2858 22.9979 9.0832 22.9979 12C22.9979 14.9168 21.8394 17.7142 19.7773 19.777C19.3868 20.1676 18.7536 20.1677 18.363 19.7772C17.9725 19.3867 17.9724 18.7536 18.3628 18.363C20.0501 16.6753 20.9979 14.3865 20.9979 12C20.9979 9.61352 20.0501 7.32475 18.3628 5.637C17.9724 5.24642 17.9725 4.61325 18.363 4.22279ZM14.833 7.75279C15.2236 7.36232 15.8568 7.36242 16.2473 7.753C17.3721 8.87817 18.004 10.404 18.004 11.995C18.004 13.586 17.3721 15.1118 16.2473 16.237C15.8568 16.6276 15.2236 16.6277 14.833 16.2372C14.4425 15.8467 14.4424 15.2136 14.8328 14.823C15.5827 14.0729 16.004 13.0557 16.004 11.995C16.004 10.9343 15.5827 9.91711 14.8328 9.167C14.4424 8.77642 14.4425 8.14325 14.833 7.75279Z"
      fill={fill}
    />
  </svg>
)

export const VideoIcon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4576 6.11084C23.7907 6.28224 24 6.62542 24 7V17C24 17.3746 23.7907 17.7178 23.4576 17.8892C23.1245 18.0606 22.7236 18.0315 22.4188 17.8137L15.4188 12.8137C15.156 12.626 15 12.323 15 12C15 11.6771 15.156 11.374 15.4188 11.1863L22.4188 6.18627C22.7236 5.96855 23.1245 5.93943 23.4576 6.11084ZM17.7205 12L22 15.0568V8.94319L17.7205 12Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6C2.44772 6 2 6.44772 2 7V17C2 17.5523 2.44772 18 3 18H14C14.5523 18 15 17.5523 15 17V7C15 6.44772 14.5523 6 14 6H3ZM0 7C0 5.34315 1.34315 4 3 4H14C15.6569 4 17 5.34315 17 7V17C17 18.6569 15.6569 20 14 20H3C1.34315 20 0 18.6569 0 17V7Z"
      fill={fill}
    />
  </svg>
)

export const FlagIcon = ({ fill }) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4 1.1C17 0.9 16.6 1 16.3 1.3C16.3 1.3 15.4 2 13 2C11.7 2 10.6 1.5 9.4 1.1C8.1 0.5 6.7 0 5 0C1.8 0 0.5 1.1 0.3 1.3C0.0999999 1.5 0 1.7 0 2V14V21C0 21.6 0.4 22 1 22C1.6 22 2 21.6 2 21V14.5C2.4 14.3 3.4 14 5 14C6.3 14 7.4 14.5 8.6 14.9C9.9 15.4 11.3 16 13 16C16.2 16 17.5 14.9 17.7 14.7C17.9 14.5 18 14.3 18 14V2C18 1.6 17.8 1.3 17.4 1.1ZM16 13.5C15.6 13.7 14.6 14 13 14C11.7 14 10.6 13.5 9.4 13.1C8.1 12.5 6.7 12 5 12C3.7 12 2.7 12.2 2 12.4V2.5C2.4 2.3 3.4 2 5 2C6.3 2 7.4 2.5 8.6 2.9C9.9 3.5 11.3 4 13 4C14.3 4 15.3 3.8 16 3.6V13.5Z"
      fill={fill}
    />
  </svg>
)

export const RightArrowIcon = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.52876 3.52864C5.78911 3.26829 6.21122 3.26829 6.47157 3.52864L10.4716 7.52864C10.7319 7.78899 10.7319 8.2111 10.4716 8.47145L6.47157 12.4714C6.21122 12.7318 5.78911 12.7318 5.52876 12.4714C5.26841 12.2111 5.26841 11.789 5.52876 11.5286L9.05735 8.00004L5.52876 4.47145C5.26841 4.2111 5.26841 3.78899 5.52876 3.52864Z"
      fill={fill}
    />
  </svg>
)

export const CloseIcon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
      fill={fill}
    />
  </svg>
)

export const StarIcon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C12.3806 1 12.7283 1.21607 12.8967 1.55738L15.7543 7.34647L22.1447 8.28051C22.5212 8.33555 22.8339 8.59956 22.9513 8.96157C23.0687 9.32358 22.9704 9.72083 22.6978 9.98636L18.0746 14.4894L19.1656 20.851C19.23 21.2261 19.0757 21.6053 18.7678 21.8291C18.4598 22.0528 18.0515 22.0823 17.7146 21.9051L12 18.8998L6.28548 21.9051C5.94856 22.0823 5.54027 22.0528 5.2323 21.8291C4.92432 21.6053 4.77007 21.2261 4.83442 20.851L5.92551 14.4894L1.3023 9.98636C1.02968 9.72083 0.931405 9.32358 1.04878 8.96157C1.16616 8.59956 1.47884 8.33555 1.8554 8.28051L8.24577 7.34647L11.1033 1.55738C11.2718 1.21607 11.6194 1 12 1ZM12 4.25925L9.80674 8.70262C9.6612 8.99747 9.38001 9.20193 9.05466 9.24949L4.14844 9.9666L7.69776 13.4236C7.93364 13.6534 8.0413 13.9845 7.98564 14.309L7.14821 19.1917L11.5346 16.8849C11.826 16.7317 12.1741 16.7317 12.4655 16.8849L16.8518 19.1917L16.0144 14.309C15.9588 13.9845 16.0664 13.6534 16.3023 13.4236L19.8516 9.9666L14.9454 9.24949C14.62 9.20193 14.3389 8.99747 14.1933 8.70262L12 4.25925Z"
      fill={fill}
    />
  </svg>
)

export const ArrowIcon = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5892 3.57739C10.2638 3.25195 9.73616 3.25195 9.41072 3.57739C9.08529 3.90283 9.08529 4.43047 9.41072 4.7559L13.8215 9.16665H4.16665C3.70641 9.16665 3.33331 9.53974 3.33331 9.99998C3.33331 10.4602 3.70641 10.8333 4.16665 10.8333H13.8215L9.41072 15.2441C9.08529 15.5695 9.08529 16.0971 9.41072 16.4226C9.73616 16.748 10.2638 16.748 10.5892 16.4226L16.4217 10.5901C16.4238 10.588 16.4259 10.5859 16.428 10.5838C16.5852 10.4237 16.6647 10.216 16.6666 10.0077C16.6666 10.0051 16.6666 10.0025 16.6666 9.99998C16.6666 9.99741 16.6666 9.99485 16.6666 9.99229C16.6656 9.88239 16.6434 9.77758 16.6037 9.68176C16.5631 9.58318 16.5027 9.49083 16.4226 9.41072L10.5892 3.57739Z"
      fill={fill}
    />
  </svg>
)

export const RightUpIcon = ({ fill }) => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8047 5.98628C12.0651 6.24663 12.0651 6.66874 11.8047 6.92909L5.13807 13.5958C4.87772 13.8561 4.45561 13.8561 4.19526 13.5958C3.93491 13.3354 3.93491 12.9133 4.19526 12.6529L10.8619 5.98628C11.1223 5.72593 11.5444 5.72593 11.8047 5.98628Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6.45768C4 6.08949 4.29848 5.79102 4.66667 5.79102H11.3333C11.7015 5.79102 12 6.08949 12 6.45768V13.1243C12 13.4925 11.7015 13.791 11.3333 13.791C10.9651 13.791 10.6667 13.4925 10.6667 13.1243V7.12435H4.66667C4.29848 7.12435 4 6.82587 4 6.45768Z"
      fill={fill}
    />
  </svg>
)

export const PlayCircleIcon = ({ fill }) => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 3.90267C7.32234 3.90267 3.12501 8.1 3.12501 13.2777C3.12501 18.4553 7.32234 22.6527 12.5 22.6527C17.6777 22.6527 21.875 18.4553 21.875 13.2777C21.875 8.1 17.6777 3.90267 12.5 3.90267ZM1.04167 13.2777C1.04167 6.94941 6.17174 1.81934 12.5 1.81934C18.8283 1.81934 23.9583 6.94941 23.9583 13.2777C23.9583 19.6059 18.8283 24.736 12.5 24.736C6.17174 24.736 1.04167 19.6059 1.04167 13.2777Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.92515 8.19259C10.2639 8.01132 10.6748 8.03119 10.9945 8.24429L17.2445 12.411C17.5343 12.6041 17.7083 12.9294 17.7083 13.2777C17.7083 13.626 17.5343 13.9512 17.2445 14.1444L10.9945 18.3111C10.6748 18.5242 10.2639 18.544 9.92515 18.3627C9.58644 18.1815 9.375 17.8285 9.375 17.4443V9.111C9.375 8.72684 9.58644 8.37386 9.92515 8.19259ZM11.4583 11.0574V15.498L14.7888 13.2777L11.4583 11.0574Z"
      fill={fill}
    />
  </svg>
)

export const CompassIcon = ({ fill }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.7915C7.02944 3.7915 3 7.82094 3 12.7915C3 17.7621 7.02944 21.7915 12 21.7915C16.9706 21.7915 21 17.7621 21 12.7915C21 7.82094 16.9706 3.7915 12 3.7915ZM1 12.7915C1 6.71637 5.92487 1.7915 12 1.7915C18.0751 1.7915 23 6.71637 23 12.7915C23 18.8666 18.0751 23.7915 12 23.7915C5.92487 23.7915 1 18.8666 1 12.7915Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9471 7.84419C17.2149 8.11202 17.3085 8.50819 17.1887 8.86752L15.0687 15.2275C14.9691 15.5261 14.7348 15.7604 14.4362 15.86L8.07623 17.98C7.71689 18.0998 7.32072 18.0062 7.05289 17.7384C6.78506 17.4706 6.69154 17.0744 6.81132 16.7151L8.93132 10.3551C9.03085 10.0565 9.26517 9.82215 9.56377 9.72261L15.9238 7.60261C16.2831 7.48284 16.6793 7.57636 16.9471 7.84419ZM10.6706 11.4619L9.34114 15.4502L13.3294 14.1207L14.6589 10.1324L10.6706 11.4619Z"
      fill={fill}
    />
  </svg>
)

export const BellIcon = ({ fill }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.05025 3.84176C8.36301 2.529 10.1435 1.7915 12 1.7915C13.8565 1.7915 15.637 2.529 16.9497 3.84176C18.2625 5.15451 19 6.93499 19 8.7915C19 12.1442 19.7171 14.2261 20.3779 15.4377C20.7097 16.0459 21.0328 16.445 21.2572 16.6819C21.3697 16.8006 21.4581 16.8793 21.5113 16.9237C21.5379 16.9459 21.5558 16.9595 21.5635 16.9652C21.5647 16.9661 21.5656 16.9668 21.5663 16.9673C21.9248 17.2136 22.0834 17.664 21.9571 18.0813C21.8294 18.503 21.4407 18.7915 21 18.7915H3C2.55933 18.7915 2.17061 18.503 2.04291 18.0813C1.91657 17.664 2.07521 17.2136 2.43369 16.9673C2.43439 16.9668 2.43535 16.9661 2.43655 16.9652C2.44425 16.9595 2.4621 16.9459 2.48872 16.9237C2.54194 16.8793 2.63032 16.8006 2.7428 16.6819C2.96723 16.445 3.29031 16.0459 3.62211 15.4377C4.28293 14.2261 5 12.1442 5 8.7915C5 6.93499 5.7375 5.15451 7.05025 3.84176ZM2.4438 16.9605C2.44388 16.9604 2.44396 16.9604 2.44404 16.9603C2.44403 16.9603 2.44403 16.9603 2.44402 16.9603L2.4438 16.9605ZM5.14933 16.7915H18.8507C18.7746 16.6668 18.6982 16.5349 18.6221 16.3954C17.7829 14.8569 17 12.4388 17 8.7915C17 7.46542 16.4732 6.19365 15.5355 5.25597C14.5979 4.31829 13.3261 3.7915 12 3.7915C10.6739 3.7915 9.40215 4.31829 8.46447 5.25597C7.52679 6.19365 7 7.46542 7 8.7915C7 12.4388 6.21707 14.8569 5.3779 16.3954C5.3018 16.5349 5.22541 16.6668 5.14933 16.7915Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.76823 20.9267C10.246 20.6495 10.8579 20.8122 11.135 21.2899C11.2229 21.4414 11.3491 21.5672 11.5009 21.6547C11.6527 21.7421 11.8248 21.7881 12 21.7881C12.1752 21.7881 12.3473 21.7421 12.4991 21.6547C12.6509 21.5672 12.7771 21.4414 12.865 21.2899C13.1421 20.8122 13.754 20.6495 14.2318 20.9267C14.7095 21.2038 14.8721 21.8157 14.595 22.2934C14.3313 22.7481 13.9528 23.1254 13.4973 23.3877C13.0419 23.6501 12.5256 23.7881 12 23.7881C11.4744 23.7881 10.9581 23.6501 10.5027 23.3877C10.0472 23.1254 9.66872 22.7481 9.405 22.2934C9.12788 21.8157 9.2905 21.2038 9.76823 20.9267Z"
      fill={fill}
    />
  </svg>
)

export const ChevronDownIcon = ({ fill }) => (
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.459519 0.474046C0.850043 0.0835212 1.48321 0.0835212 1.87373 0.474046L7.16663 5.76694L12.4595 0.474046C12.85 0.0835212 13.4832 0.0835212 13.8737 0.474046C14.2643 0.86457 14.2643 1.49773 13.8737 1.88826L7.87373 7.88826C7.48321 8.27878 6.85004 8.27878 6.45952 7.88826L0.459519 1.88826C0.0689949 1.49773 0.0689949 0.86457 0.459519 0.474046Z"
      fill={fill}
    />
  </svg>
)

export const ChevronUpIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45952 0.570725C6.85004 0.180201 7.48321 0.180201 7.87373 0.570725L13.8737 6.57073C14.2643 6.96125 14.2643 7.59441 13.8737 7.98494C13.4832 8.37546 12.85 8.37546 12.4595 7.98494L7.16663 2.69205L1.87373 7.98494C1.48321 8.37546 0.850043 8.37546 0.459519 7.98494C0.0689949 7.59441 0.0689949 6.96125 0.459519 6.57073L6.45952 0.570725Z"
      fill={fill}
    />
  </svg>
)

export const LinkedinIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <g clipPath="url(#clip0_1440_4064)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3714 7.50016C12.2664 7.50016 11.2065 7.93915 10.4251 8.72055C9.64374 9.50195 9.20475 10.5618 9.20475 11.6668V16.6668H10.8714V11.6668C10.8714 11.0038 11.1348 10.3679 11.6037 9.89906C12.0725 9.43022 12.7084 9.16683 13.3714 9.16683C14.0345 9.16683 14.6703 9.43022 15.1392 9.89906C15.608 10.3679 15.8714 11.0038 15.8714 11.6668V16.6668H17.5381V11.6668C17.5381 10.5618 17.0991 9.50195 16.3177 8.72055C15.5363 7.93915 14.4765 7.50016 13.3714 7.50016ZM13.3714 5.8335C11.8243 5.8335 10.3406 6.44808 9.24663 7.54204C8.15267 8.636 7.53809 10.1197 7.53809 11.6668V17.5002C7.53809 17.9604 7.91118 18.3335 8.37142 18.3335H11.7048C12.165 18.3335 12.5381 17.9604 12.5381 17.5002V11.6668C12.5381 11.4458 12.6259 11.2339 12.7822 11.0776C12.9384 10.9213 13.1504 10.8335 13.3714 10.8335C13.5924 10.8335 13.8044 10.9213 13.9607 11.0776C14.117 11.2339 14.2048 11.4458 14.2048 11.6668V17.5002C14.2048 17.9604 14.5778 18.3335 15.0381 18.3335H18.3714C18.8317 18.3335 19.2048 17.9604 19.2048 17.5002V11.6668C19.2048 10.1197 18.5902 8.636 17.4962 7.54204C16.4022 6.44808 14.9185 5.8335 13.3714 5.8335Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.871338 7.49984C0.871338 7.0396 1.24443 6.6665 1.70467 6.6665H5.038C5.49824 6.6665 5.87134 7.0396 5.87134 7.49984V17.4998C5.87134 17.9601 5.49824 18.3332 5.038 18.3332H1.70467C1.24443 18.3332 0.871338 17.9601 0.871338 17.4998V7.49984ZM2.538 8.33317V16.6665H4.20467V8.33317H2.538Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.37134 2.50016C2.9111 2.50016 2.538 2.87326 2.538 3.3335C2.538 3.79373 2.9111 4.16683 3.37134 4.16683C3.83157 4.16683 4.20467 3.79373 4.20467 3.3335C4.20467 2.87326 3.83157 2.50016 3.37134 2.50016ZM0.871338 3.3335C0.871338 1.95278 1.99063 0.833496 3.37134 0.833496C4.75205 0.833496 5.87134 1.95278 5.87134 3.3335C5.87134 4.71421 4.75205 5.8335 3.37134 5.8335C1.99063 5.8335 0.871338 4.71421 0.871338 3.3335Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1440_4064">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.0380859)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const TalkIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.20837 5.08675C4.93211 5.08675 4.66715 5.1965 4.4718 5.39185C4.27645 5.5872 4.16671 5.85215 4.16671 6.12842V20.2803L6.55514 17.8918C6.75049 17.6965 7.01544 17.5868 7.29171 17.5868H19.7917C20.068 17.5868 20.3329 17.477 20.5283 17.2817C20.7236 17.0863 20.8334 16.8214 20.8334 16.5451V6.12842C20.8334 5.85215 20.7236 5.5872 20.5283 5.39185C20.3329 5.1965 20.068 5.08675 19.7917 5.08675H5.20837ZM2.99867 3.91871C3.58472 3.33266 4.37957 3.00342 5.20837 3.00342H19.7917C20.6205 3.00342 21.4154 3.33266 22.0014 3.91871C22.5875 4.50476 22.9167 5.29962 22.9167 6.12842V16.5451C22.9167 17.3739 22.5875 18.1687 22.0014 18.7548C21.4154 19.3408 20.6205 19.6701 19.7917 19.6701H7.72318L3.86161 23.5317C3.5637 23.8296 3.11566 23.9187 2.72641 23.7575C2.33717 23.5962 2.08337 23.2164 2.08337 22.7951V6.12842C2.08337 5.29962 2.41261 4.50476 2.99867 3.91871Z"
      fill={fill}
    />
  </svg>
)
