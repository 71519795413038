import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ArrowIcon } from './virtualLibrary/Icons'
import mailbox from '../assets/mailbox.png'
import NavArrowWhite from '../assets/nav-arrow_white.svg'

const MailchimpFormContainer = styled.div`
  max-width: 964px;
  width: 100%;
  padding: 0;
  margin: auto;

  @media screen and (min-width: 740px) {
    padding: 0 24px;
    margin-bottom: 50px;
  }
`

const MailchimpForm = styled.div`
  clear: left;
  font-family: Poppins;
  ${'' /* max-width: 510px; */}
  background-color: #efeef7;
  margin-top: 20px;
  padding: 0 17px;
  position: relative;

  @media screen and (min-width: 740px) {
    height: 63px;
  }
`

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  padding-left: 72px;
  padding-bottom: 10px;
  padding-right: 0;

  height: ${({ clicked, library }) => (clicked && !library ? '60px' : '90px')};
  transition: height 200ms ease-in-out;

  & .mc-field-group {
    position: ${(library) => (library ? 'absolute' : 'static')};
    left: ${({ clicked, library }) => (clicked && library ? '20px' : '90px')};

    @media screen and (min-width: 740px) {
      position: static;
      left: 0;
    }
  }

  @media screen and (min-width: 740px) {
    flex-direction: row;
    align-items: center;
    padding-bottom: 0;
    height: 63px;
  }
  @media screen and (min-width: 1000px) {
    padding-right: 72px;
  }
`

const MailboxImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 69.15px;
  height: 78px;
  transition: left 500ms ease-in-out;

  @media screen and (min-width: 740px) {
    left: ${({ clicked }) => (clicked ? '24px' : '0')};
  }
  @media screen and (min-width: 1000px) {
    left: 65px;
  }
`

const Title = styled.h2`
  font-size: ${(library) => (library ? '12px' : '14px')};
  color: #000;
  font-weight: bold;
  line-height: ${(library) => (library ? '18px' : '22px')};
  padding-right: ${(library) => (library ? '0' : '11px')};
  position: absolute;
  width: ${(library) => (library ? 'auto' : '180px')};
  left: 90px;
  top: 0;
  opacity: ${({ clicked }) => (clicked ? '0' : '1')};
  transition: opacity 200ms ease-in-out;

  @media screen and (min-width: 740px) {
    width: 235px;
    left: 90px;
    font-size: 14px;
    line-height: 22px;
    padding-right: 11px;
  }
  @media screen and (min-width: 1000px) {
    left: 150px;
  }
`

const EmailInput = styled.input`
  padding: 5px 0 6px 10px;
  border-radius: ${({ clicked }) => (clicked ? '4px' : '4px 0px 0px 4px')};
  font-size: 12px;
  line-height: 18px;
  border: none;
  ${
    '' /* width: ${({ clicked, library }) => (clicked && library ? '48vw' : '100%')}; */
  }
  width: 50vw;
  color: ${({ theme }) => theme.colors.textGray};
  transition: width 500ms ease-in-out;
  z-index: 1;

  @media screen and (min-width: 740px) {
    ${({ clicked }) => (clicked ? 'margin-left: 56px;' : '')}
    ${'' /* width: ${({ clicked }) => (clicked ? '237px' : '139px')}; */}
    width: 37vw;
    max-width: 400px;
  }
`

const JoinButton = styled.button`
  padding: ${({ clicked }) => (clicked ? '6px 14px' : '4.5px 5px')};
  border-radius: ${({ clicked }) => (clicked ? '100px' : '4px')};
  ${({ clicked }) => (clicked ? 'margin-left: 6px;' : '')}
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  border: none;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.brandPrimary};
  cursor: pointer;
  opacity: 1;
  display: flex;
  align-items: center;
  width: auto;

  :hover {
    opacity: 0.7;
  }
`

const ErrorResponse = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.textError};
`

const SuccessResponse = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.textSuccess};
`

const MailchimpEmailSignup = ({ title, cta, library }) => {
  const [clicked, setClicked] = useState(false)

  return (
    <MailchimpFormContainer onClick={() => setClicked(true)} clicked={clicked}>
      {/* <link
      href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
      rel="stylesheet"
      type="text/css"
    /> */}
      <style type="text/css">
        {`
        div.mce_inline_error {
          display: none !important;
        }
  `}
      </style>
      <MailchimpForm id="mc_embed_signup">
        <form
          action="https://innential.us6.list-manage.com/subscribe/post?u=e04d8d69eb3e9dbe6435dc345&amp;id=00033feea3"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <MailboxImage alt="mailbox" src={mailbox} clicked={clicked} />
            <FormWrapper clicked={clicked} library={library}>
              <Title clicked={clicked} library={library}>
                {title}
              </Title>
              <div className="mc-field-group">
                <div style={{ display: 'flex' }}>
                  <EmailInput
                    type="email"
                    name="EMAIL"
                    placeholder="Enter email"
                    className="required email"
                    id="mce-EMAIL"
                    clicked={clicked}
                    library={library}
                  />
                  <div className="clear">
                    <JoinButton
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                      clicked={clicked}
                    >
                      {clicked ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          Subscribe
                          <img src={NavArrowWhite} alt="arrow" />
                        </div>
                      ) : (
                        <ArrowIcon fill="#fff" />
                      )}
                    </JoinButton>
                  </div>
                </div>
              </div>
              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_e04d8d69eb3e9dbe6435dc345_102507704f"
                  tabIndex="-1"
                  value=""
                />
              </div>
            </FormWrapper>
            <div id="mce-responses" className="clear">
              <ErrorResponse
                className="response"
                id="mce-error-response"
                style={{ display: 'none' }}
              />
              <SuccessResponse
                className="response"
                id="mce-success-response"
                style={{ display: 'none' }}
              />
            </div>
          </div>
        </form>
      </MailchimpForm>
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      />
      <script type="text/javascript">{`function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);`}</script>
    </MailchimpFormContainer>
  )
}

MailchimpEmailSignup.propTypes = {
  title: PropTypes.string,
  cta: PropTypes.string,
}

MailchimpEmailSignup.defaultProps = {
  title: 'Stay updated with the latest L&D content and events',
  cta: 'Join',
}

export default MailchimpEmailSignup
