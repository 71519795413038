import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import NavArrow from '../assets/nav-arrow.svg'
import OnboardingIcon from '../assets/Group-person.svg'
import CareerIcon from '../assets/career-path.svg'
import ContinuosIcon from '../assets/Trending-up.svg'
import TrainingIcon from '../assets/Folder-Marked.svg'
import UpIcon from '../assets/chevron-left.svg'
import CertBGIcon from '../assets/cert-bg.svg'
import AwardIcon from '../assets/award.svg'
import LPIcon from '../assets/Icon-lp.svg'
import GuideIcon from '../assets/file-text.svg'
import LibraryIcon from '../assets/library.svg'
import BlogIcon from '../assets/blog.svg'

const NavWrapper = styled.div`
  ${
    '' /* padding-right: 45px;
  color: #8494b2;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.2s ease-in; */
  }
`

const NavItem = styled.div`
  padding-right: 45px;
  display: flex;
  align-items: center;
  color: #8494b2;
  cursor: pointer;
  transition: color 0.2s ease-in;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 18px 0px;
`
const NavItemImgContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`

const NavLink = styled(Link)`
  color: #191919;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: color 0.2s ease-in;
  &:hover {
    color: #5a55ab;
  }
  span {
    color: #fff !important;
    background-color: #2fbbb0;
    border-radius: 24px;
    padding: 0px 6px !important;
    font-size: 10px !important;
    font-weight: 700;
    line-height: 16px;
    width: 38px !important;
    text-align: center;
    margin-left: 4px;
  }
`
const DropdownTrigger = styled.div`
  position: relative;
  color: #191919;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  display: flex;
  width: 100%;
  cursor: pointer;
  padding-bottom: 18px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &:before {
    display: none;
  }
  &:hover {
    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 150px;
      height: 150px;
    }
  }
`
const DropdownMenuContainer = styled.div`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  height: ${(props) =>
    props.show && props.isResources ? '268px' : props.show ? '307px' : '0'};
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: height 0.3s ease, opacity 0.3s ease-in-out, visibility 0.3s ease;
`
const ArrowDown = styled.span`
  margin-left: auto;

  img {
    width: 20px;
    height: 18px;
    transform: rotate(90deg);
  }
`
const ArrowUp = styled.span`
  margin-left: auto;

  img {
    width: 20px;
    height: 14px;
    transform: rotate(90deg);
  }
`

function DropdownMobile({ closeMobileNavigation, isResources }) {
  const [isShown, setIsShown] = useState(false)
  const [resourcesShown, setResourcesShown] = useState(false)

  return (
    <NavWrapper>
      {isResources ? (
        <>
          <>
            <DropdownTrigger onClick={() => setResourcesShown(!resourcesShown)}>
              <span
                style={{
                  color: resourcesShown ? '#5A55AB' : '#191919',
                  fontWeight: 600,
                  display: 'inline-block',
                  transition: 'transform 0.3s ease', // Add transition for smooth animation
                }}
              >
                Resources
              </span>

              {resourcesShown ? (
                <ArrowUp>
                  <img src={UpIcon} alt="arrow up" />
                </ArrowUp>
              ) : (
                <ArrowDown>
                  <img src={NavArrow} alt="arrow down" />
                </ArrowDown>
              )}
            </DropdownTrigger>

            <DropdownMenuContainer show={resourcesShown} isResources>
              <NavItem onClick={closeMobileNavigation}>
                <NavItemImgContainer>
                  <img src={LPIcon} alt="learning paths" />
                </NavItemImgContainer>
                <NavLink to="/learning-paths">Learning Paths</NavLink>
              </NavItem>
              <NavItem onClick={closeMobileNavigation}>
                <NavItemImgContainer>
                  <img src={GuideIcon} alt="guides" />{' '}
                </NavItemImgContainer>
                <NavLink to="/download/people-development-blueprint">
                  People Development Blueprint
                  <span>NEW</span>
                </NavLink>
              </NavItem>
              <NavItem onClick={closeMobileNavigation}>
                <NavItemImgContainer>
                  <img src={LibraryIcon} alt="library" />
                </NavItemImgContainer>
                <NavLink to="/virtual-library/read">L&D Library</NavLink>
              </NavItem>
              <NavItem onClick={closeMobileNavigation}>
                <NavItemImgContainer>
                  <img src={BlogIcon} alt="blog" />
                </NavItemImgContainer>
                <NavLink to="/blog">Blog</NavLink>
              </NavItem>
            </DropdownMenuContainer>
          </>
        </>
      ) : (
        <>
          <DropdownTrigger onClick={() => setIsShown(!isShown)}>
            <span
              style={{
                color: isShown ? '#5A55AB' : '#191919',
                fontWeight: 600,
                display: 'inline-block',
                transition: 'transform 0.3s ease', // Add transition for smooth animation
              }}
            >
              Solutions
            </span>

            {isShown ? (
              <ArrowUp>
                <img src={UpIcon} alt="arrow up" />
              </ArrowUp>
            ) : (
              <ArrowDown>
                <img src={NavArrow} alt="arrow down" />
              </ArrowDown>
            )}
          </DropdownTrigger>

          <DropdownMenuContainer show={isShown}>
            <NavItem onClick={closeMobileNavigation}>
              <NavItemImgContainer>
                <img src={OnboardingIcon} alt="onboarding" />
              </NavItemImgContainer>
              <NavLink to="/use-cases/onboarding">Onboarding</NavLink>
            </NavItem>
            <NavItem onClick={closeMobileNavigation}>
              <NavItemImgContainer>
                <img src={CareerIcon} alt="career paths" />{' '}
              </NavItemImgContainer>
              <NavLink to="/use-cases/career-paths">Career Paths</NavLink>
            </NavItem>

            <NavItem onClick={closeMobileNavigation}>
              <NavItemImgContainer>
                <img src={ContinuosIcon} alt="continuous education" />
              </NavItemImgContainer>
              <NavLink to="/use-cases/continuous-education">
                Continuous Education
              </NavLink>
            </NavItem>
            <NavItem onClick={closeMobileNavigation}>
              <NavItemImgContainer>
                <img src={TrainingIcon} alt="internal training" />{' '}
              </NavItemImgContainer>
              <NavLink to="/use-cases/internal-training">
                Internal Training
              </NavLink>
            </NavItem>
            <NavItem onClick={closeMobileNavigation}>
              <NavItemImgContainer>
                <img src={CertBGIcon} alt="Certifications" />
                <img
                  src={AwardIcon}
                  alt="Certifications"
                  style={{
                    position: 'absolute',
                    width: '11px',
                    height: '11px',
                    left: '5px',
                  }}
                />
              </NavItemImgContainer>
              <NavLink to="/use-cases/certifications">Certifications</NavLink>
            </NavItem>
          </DropdownMenuContainer>
        </>
      )}
    </NavWrapper>
  )
}

export default DropdownMobile
