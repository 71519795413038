import PropTypes from 'prop-types'
import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Layout = ({ children }) => {
  const location =
    children?.props?.children?.props?.location || children?.props?.location

  const pathname = location?.pathname || ''

  // matches /blog/... but not /blog or /blog/
  const isBlogPost = new RegExp(/^\/blog\/./).test(pathname)

  const isUseCases = new RegExp(/\/use-cases/).test(pathname)

  const isSignUp = new RegExp(/\/sign-up/).test(pathname)

  const isAppointment = new RegExp(/\/appointment/).test(pathname)

  const isSignUpOrAppointment = isSignUp || isAppointment

  const isLibrary = new RegExp(/^\/virtual-library\/?/).test(pathname)

  const isBlog = new RegExp(/^\/blog(\/.*)?$/).test(pathname)

  const isLearningPaths = new RegExp(/^\/learning-paths(\/.*)?$/).test(pathname)
  const isGuide = new RegExp(/^\/guide(\/.*)?$/).test(pathname)
  const isDownload = new RegExp(/^\/download(\/.*)?$/).test(pathname)

  const isLandingPage = new RegExp(/^\/$/).test(pathname)

  if (new RegExp(/^\/company\/?/).test(pathname)) {
    return <>{children}</>
  }

  return (
    <>
      <Header
        solid={isBlogPost}
        library={isLibrary}
        isSignUpOrAppointment={isSignUpOrAppointment}
        isBlog={isBlog}
        isLearningPaths={
          isLearningPaths || isGuide || isBlog || isDownload || isLibrary
        }
        isUseCases={isUseCases}
        isLandingPage={isLandingPage}
      />
      <main
        style={{
          background: isLibrary ? '#f7f7ff' : '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <div
          style={{
            padding: isLandingPage
              ? '62px 0 0'
              : isAppointment
              ? `75px 0 0`
              : isLibrary
              ? `100px 0 0`
              : `120px 0 0`,
          }}
        >
          {children}
        </div>
        <Footer white={isUseCases || false} isLandingPage={isLandingPage} />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
