import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Burger from './Burger'
import MailchimpEmailSignup from './MailchimpEmailSignup'
import logo from '../assets/innential.svg'
import logoLibrary from '../assets/logo-lndshakers.png'
import logoWhite from '../assets/innential_white.svg'
import mq from '../utils/mq'

const StyledHeader = styled.header`
  position: fixed;
  margin: 0 auto;
  padding: 5px 0 10px;
  width: 100%;
  z-index: 9999;
  align-items: center;
  background-color: ${({ library, theme, transparent }) => {
    if (library && !transparent) {
      return theme.colors.footerBg
    }
    if (library && transparent) {
      return transparent ? theme.colors.bodyBg : theme.colors.footerBg
    }
    return theme.colors.bodyBg
  }};
  transition: background-color 0.3s ease-out;
  border-bottom: ${({ isLandingPage }) =>
    isLandingPage ? '1px solid #E1EDF7' : 'none'};

  @media (max-width: 1024px) {
    padding: 15px 0 15px;
  }

  @media (max-width: 740px) {
    background-color: ${({ library, theme }) =>
      library ? theme.colors.footerBg : theme.colors.bodyBg};
  }
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1300px;
  ${
    '' /* padding: ${({ library }) => (library ? '0px 1.0875rem' : '0px 30px')}; */
  }
  padding: 0px 30px;
  padding-top: 0;
`

const LibraryMailchimpLowerOnMobile = styled.div`
  @media screen and (min-width: 740px) {
    display: none;
  }
`

const UnderText = styled.div`
  white-space: nowrap;
  position: absolute;
  font-size: 14px;
  top: 24px;
  color: #000;
  @media screen and (min-width: 1024px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 14px;
  }
`

const LibraryMailchimpHigherOnMobile = styled.div`
  @media screen and (max-width: 740px) {
    display: none;
  }
`

const LibraryBurgerOnMobile = styled.div`
  position: relative;
  bottom: 25px;
  @media screen and (min-width: 740px) {
    display: none;
  }
`

const InnerHeader = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  /* flex-direction: ${({ library }) => (library ? 'column' : 'row')}; */
  flex-direction: row;

  @media screen and (min-width: 740px) {
    flex-direction: row;
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    padding: 0px;
  }
`

const Header = ({
  solid,
  library,
  isSignUpOrAppointment,
  isBlog,
  isLearningPaths,
  isUseCases,
  isLandingPage,
}) => {
  const [nav, setNav] = useState(false)

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY > 45 && library) {
        setNav(true)
      } else {
        setNav(false)
      }
    }

    window.addEventListener('scroll', changeBackground)

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', changeBackground)
    }
  }, [])

  const LogoLink = styled(Link)`
    position: relative;
    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: #5a55ab;
    }
  `
  const showWhiteLogo = solid && nav

  const logoSource = logo
  // if (library) {
  //   logoSource = logoLibrary
  // }
  // else if (showWhiteLogo) {
  //   logoSource = logoWhite
  // }

  return (
    <StyledHeader
      transparent={nav}
      library={library}
      isLandingPage={isLandingPage}
    >
      <Container library={library}>
        <InnerHeader library={library}>
          <LogoLink
            to={isLandingPage ? '/' : '/learning'}
            // onClick={() => library && window.location.reload()}
          >
            <img
              src={logoSource}
              alt="Innential Logo"
              style={{
                height: `auto`,
                width: `110px`,
                // width: library ? `181px` : `110px`,
                paddingTop: 2,
              }}
            />
            {library && <UnderText>People Development Library</UnderText>}
          </LogoLink>
          {/* {library && (
            <LibraryBurgerOnMobile>
              <Burger white={showWhiteLogo} library={library} />
            </LibraryBurgerOnMobile>
          )}

          {library && (
            <LibraryBurgerOnMobile>
              <Burger white={showWhiteLogo} library={library} />
            </LibraryBurgerOnMobile>
          )} */}

          <Burger
            white={showWhiteLogo}
            library={library}
            isSignUpOrAppointment={isSignUpOrAppointment}
            isBlog={isBlog}
            isLearningPaths={isLearningPaths}
            isUseCases={isUseCases}
            isLandingPage={isLandingPage}
          />

          {/* <LibraryMailchimpHigherOnMobile>
            {library && <MailchimpEmailSignup library={library} />}
          </LibraryMailchimpHigherOnMobile> */}
        </InnerHeader>
        <LibraryMailchimpLowerOnMobile>
          {library && <MailchimpEmailSignup library={library} />}
        </LibraryMailchimpLowerOnMobile>
      </Container>
    </StyledHeader>
  )
}

Header.propTypes = {
  solid: PropTypes.bool,
  library: PropTypes.bool,
}

Header.defaultProps = {
  solid: false,
  library: false,
}

export default Header
