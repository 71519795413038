import React, { useState } from 'react'
import styled from 'styled-components'
import MobileNavigation from './MobileNavigation'
import Navigation from './Navigation'

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 30px;
  z-index: 9999;
  display: none;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? '#ccc' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

const Burger = ({
  white,
  library,
  isSignUpOrAppointment,
  isBlog,
  isLearningPaths,
  isUseCases,
  isLandingPage,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledBurger
        open={open}
        onClick={() => setOpen(!open)}
        library={library}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
      <Navigation
        white={white}
        isSignUpOrAppointment={isSignUpOrAppointment}
        isBlog={isBlog}
        isLearningPaths={isLearningPaths}
        isUseCases={isUseCases}
        isLibrary={library}
        isLandingPage={isLandingPage}
      />
      <MobileNavigation
        open={open}
        closeMobileNavigation={() => setOpen(false)}
        isLandingPage={isLandingPage}
      />
    </>
  )
}

export default Burger
