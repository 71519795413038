/* eslint-disable no-shadow */
import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import Font1 from '../assets/fonts/Poppins-Regular.ttf'
import Font2 from '../assets/fonts/Poppins-Medium.ttf'
import Font3 from '../assets/fonts/Poppins-SemiBold.ttf'
import Font4 from '../assets/fonts/Poppins-Bold.ttf'

export const theme = {
  colors: {
    brandPrimary: `#5a55ab`,
    brandSecondary: `#b8dee2`,
    brandGrey: `#939393`,
    brandAccent: `#2fbbb0`,
    brandInfo: `#347eb6`,
    brandWarning: `#e89c36`,
    bodyBg: `#ffffff`,
    footerBg: `#f7f7ff`,
    colorLead: `#5a55ab`,
    textColor: `#1d1d1b`,
    linkColor: `#5a55ab`,
    linkHover: `#8c88c4`,
    textGray: `#8494B2`,
    textError: `#FB5854`,
    textSuccess: `#1CB55C`,
  },
  typo: {
    // font sizes
    fontSizeXsmall: `12px`,
    fontSizeSmall: `14px`,
    fontSizeBase: `16px`,
    fontSizeLarge: `24px`,
    fontSizeH1: `80px`,
    fontSizeH2: `30px`,
    fontSizeH3: `20px`,
    fontSizeH4: `16px`,

    // font weights
    thin: `400`,
    regular: `500`,
    bold: `600`,
    xBold: `700`,

    // line hight
    lineHightBase: `1.56`,
  },
}

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  @font-face {
    font-family: 'Poppins';
    src: url(${Font1}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body {
    font-family: "Poppins","MyriadPro Regular",sans-serif;
    font-size:  ${({ theme }) => theme.typo.fontSizeBase};
    color: ${({ theme }) => theme.colors.textColor};
    line-height: 1.5;
    letter-spacing: 0;
    margin: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1, h2 {
    font-weight: ${({ theme }) => theme.typo.xBold};
  }
  p {
    font-weight: ${({ theme }) => theme.typo.regular};
    font-style: normal;
    line-height: 1.45;
  }
  a {
    color: ${({ theme }) => theme.colors.linkColor};
    text-decoration: none;

    &:hover,
    &:active,
    &:focus,
    a:visited {
      color: ${({ theme }) => theme.colors.linkHover};
    }
  }
`
